import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import Button from '@components/Button';
import { t } from 'i18next';
import { useHotkeys, useHotkeysContext } from 'react-hotkeys-hook';
import { useNavigate } from 'react-router';

import { useProfileModal } from '@/contexts/ModalPreferenceContext';
import { UserContext } from '@/contexts/UserContext';
import useDeleteWorkspace from '@/hooks/workspace/useDeleteWorkspace';
import { ROUTES } from '@/types/routes';

import Input from '../../Input';
import ModalWarning from '../ModalWarning';
import styles from './styles.module.css';

const MODAL_SCOPE = 'ModaldeleteWorkspace-scope';
type Props = {
  isOpen: boolean;
  onClose: () => void;
};
export default function ModalDeleteWorkspace({ isOpen, onClose }: Props) {
  const { workspace } = useContext(UserContext);
  const { closeModal } = useProfileModal();
  const { mutate: deleteWorkspaceAPI, isLoading } = useDeleteWorkspace();
  const [value, setValue] = useState('');
  const naviagate = useNavigate();

  const onChangeFn = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const { disableScope, enableScope } = useHotkeysContext();
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    ref?.current?.focus();
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) enableScope(MODAL_SCOPE);
    else disableScope(MODAL_SCOPE);
    return () => disableScope(MODAL_SCOPE);
  }, [disableScope, enableScope, isOpen]);

  useHotkeys(
    'enter',
    () => value && value === workspace?.name && !isLoading && deleteWorkspace(),
    { scopes: MODAL_SCOPE, enableOnFormTags: true, preventDefault: true },
  );

  const deleteWorkspace = useCallback(() => {
    if (workspace?.id)
      deleteWorkspaceAPI(workspace?.id, {
        onSuccess: () => {
          onClose();
          closeModal();
          naviagate(ROUTES.ROOT, { replace: true });
        },
      });
  }, [closeModal, deleteWorkspaceAPI, naviagate, onClose, workspace?.id]);

  return (
    <ModalWarning
      isOpen={isOpen}
      modalKey={`modal-delete-workspace`}
      onClose={onClose}
      warningDescription={t('common:modals.deleteWorkspace.description')}
      title={t('common:modals.deleteWorkspace.title')}
      footer={
        <>
          <Button
            label={t('common:modals.deleteWorkspace.primaryCtaLabel')}
            onClick={deleteWorkspace}
            isLoading={isLoading}
            disabled={value !== workspace?.name || isLoading}
            variant="destructive"
            size="medium"
          />
          <Button
            label={t('common:cancel')}
            onClick={onClose}
            size="medium"
            variant="ghost"
          />
        </>
      }
    >
      <p className={styles.actionLabel}>
        {t('common:modals.deleteWorkspace.actionLabel', {
          name: workspace?.name,
        })}
      </p>
      <Input ref={ref} value={value} onChange={onChangeFn} name="destroy" />
    </ModalWarning>
  );
}
