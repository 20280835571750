import { useContext } from 'react';

import { getAuth } from '@firebase/auth';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';
import { useNavigate } from 'react-router';

import { NotificationsContext } from '@/contexts/NotificationContext';
import { UserContext } from '@/contexts/UserContext';
import { http } from '@/services/api';
import { ROUTES } from '@/types/routes';

import { ANALYTICS_EVENTS, useAnalytics } from '../utils/useAnalytics';

export default function useDeleteMyAccount() {
  const queryClient = useQueryClient();
  const auth = getAuth();

  const { workspaceId, clearLocalStorage } = useContext(UserContext);
  const { addNotification } = useContext(NotificationsContext);
  const { trackEvent } = useAnalytics();
  const navigate = useNavigate();
  return useMutation<void, Error, void>(
    async () => {
      await http.delete(`accounts/me`);
    },
    {
      onSuccess: async () => {
        if (workspaceId)
          await trackEvent(ANALYTICS_EVENTS.ACCOUNT_DELETED, workspaceId);
        queryClient.clear();
        await auth?.signOut();

        clearLocalStorage();
        navigate(ROUTES.SIGN_IN, {
          preventScrollReset: false,
          replace: true,
        });
      },
      onError: (error) => {
        addNotification({
          type: 'error',
          title: t('errors:generic.title') as string,
          description:
            error?.message ?? (t('errors:generic.description') as string),
        });
      },
    },
  );
}
