import { useCallback, useContext } from 'react';

import { IconRefresh } from '@tabler/icons-react';

import { useProfileModal } from '@/contexts/ModalPreferenceContext';
import { UserContext, useSubscription } from '@/contexts/UserContext';
import useSubscriptionSync from '@/hooks/workspace/useSubscriptionSync';

import Button from '@/components/Button';
import FreePlanCard from '@/components/PlanCard/FreePlanCard';
import ProPlanCard from '@/components/PlanCard/ProPlanCard';
import Section from '@/components/Section';
import UsageProgress from '@/components/UsageProgress';

import styles from './styles.module.css';

export default function ModalPlans() {
  const { usageInfoData: { overflow } = { overflow: false }, workspace } =
    useContext(UserContext);

  const { subscription, isPending } = useSubscription();

  const { closeModal } = useProfileModal();

  const { mutate: syncSubscription, isLoading } = useSubscriptionSync();

  const updateSubscriptionSync = useCallback(() => {
    if (workspace?.id) syncSubscription(workspace?.id);
  }, [syncSubscription, workspace?.id]);

  return (
    <Section>
      <UsageProgress />
      <div className={styles.container}>
        <p className={styles.planCardDescription}>
          {overflow
            ? "You've reached your plan limits. Upgrade to unlock more active projects."
            : !subscription
              ? 'Upgrade to unlock more active projects.'
              : isPending && (
                  <Button
                    type="button"
                    size="medium"
                    onClick={updateSubscriptionSync}
                    icon={IconRefresh}
                    label="Refreh subscription"
                    isLoading={isLoading}
                    disabled={isLoading}
                  />
                )}
        </p>
        <div className={styles.usageCardsContainer}>
          <FreePlanCard />
          <ProPlanCard onCheckoutComplete={closeModal} />
        </div>
      </div>
    </Section>
  );
}
