import { useContext } from 'react';

// import { acceptConsent } from '@/services/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';

import { NotificationsContext } from '@/contexts/NotificationContext';
import { acceptConsent } from '@/services/api/account';

import { ACCOUNT_CONSENT_QUERY_KEY } from '../account/useAccountConsentQuery';
import { ACCOUNT_QUERY_KEY } from '../account/useAccountQuery';

export default function useAcceptConsent() {
  const { addNotification } = useContext(NotificationsContext);

  const queryClient = useQueryClient();

  return useMutation<void, Error, string>(
    async (html: string): Promise<void> => {
      await acceptConsent({ html });
    },
    {
      onError: (error: Error) => {
        addNotification({
          type: 'error',
          title: t('errors:generic.title') as string,
          description:
            error?.message ?? (t('errors:generic.description') as string),
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries([ACCOUNT_QUERY_KEY]);
        queryClient.invalidateQueries([ACCOUNT_CONSENT_QUERY_KEY]);
      },
    },
  );
}
