import { useContext } from 'react';

import { useQuery } from '@tanstack/react-query';

import { UserContext } from '@/contexts/UserContext';
import { getAcountConsent } from '@/services/api/account';

export const ACCOUNT_CONSENT_QUERY_KEY = 'get-account-consent';
export default function useAccountConsentQuery() {
  const { user } = useContext(UserContext);
  return useQuery(
    [ACCOUNT_CONSENT_QUERY_KEY, user?.uid],
    async () => await getAcountConsent(),
    { enabled: !!user?.uid },
  );
}
