import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { UserContext } from '@contexts/UserContext';
import { getAuth } from '@firebase/auth';
import { AnalyticsBrowser } from '@june-so/analytics-next';
import { get } from 'lodash';
import UAParser from 'ua-parser-js';

import { USER_ROLE, WORKSPACE_MEMBER_PERMISSION } from '@/types/enums';

const key = import.meta.env.VITE_JUNE_API_KEY;

export enum ANALYTICS_EVENTS {
  SIGNED_UP = 'SIGNED_UP',
  PERSON_CREATED = 'PERSON_CREATED',
  PERSON_INVITED = 'PERSON_INVITED',
  PROJECT_CREATED = 'PROJECT_CREATED',
  PROJECT_VIEWED = 'PROJECT_VIEWED',
  PROJECT_ARCHIVED = 'PROJECT_ARCHIVED',
  PROJECT_UNARCHIVED = 'PROJECT_UNARCHIVED',
  PROJECT_HIDDEN = 'PROJECT_HIDDEN',
  BLOCK_CREATED = 'BLOCK_CREATED',
  BLOCK_UPDATED = 'BLOCK_UPDATED',
  BLOCK_DELETED = 'BLOCK_DELETED',
  USER_INVITED = 'USER_INVITED',
  TIMELINE_VIEWED = 'TIMELINE_VIEWED',
  INSIGHT_OPENED = 'INSIGHT_OPENED',
  SWITCHED_TIME_MODE = 'SWITCHED_TIME_MODE',
  AUTO_UPDATE_STATUS_TURNED_ON = 'AUTO_UPDATE_STATUS_TURNED_ON',
  AUTO_UPDATE_STATUS_TURNED_OFF = 'AUTO_UPDATE_STATUS_TURNED_OFF',
  AUTO_HIDE_PROJECT_TURNED_ON = 'AUTO_HIDE_PROJECT_TURNED_ON',
  AUTO_HIDE_PROJECT_TURNED_OFF = 'AUTO_HIDE_PROJECT_TURNED_OFF',
  PERSONAL_ACCESS_TOKEN_CREATED = 'PERSONAL_ACCESS_TOKEN_CREATED',
  PERSONAL_ACCESS_TOKEN_REVOKED = 'PERSONAL_ACCESS_TOKEN_REVOKED',
  BLOCK_DETAIL_UPDATED = 'BLOCK_DETAIL_UPDATED',
  CHECKOUT_OPENED = 'CHECKOUT_OPENED',
  SUBSCRIPTION_CANCELED = 'SUBSCRIPTION_CANCELED',
  SUBSCRIPTION_UPDATED = 'SUBSCRIPTION_UPDATED',
  SUBSCRIPTION_CREATED = 'SUBSCRIPTION_CREATED',
  WORKSPACE_DELETED = 'WORKSPACE_DELETED',
  ACCOUNT_DELETED = "ACCOUNT_DELETED",
}

export enum ANALYTICS_PAGE_NAME {
  PROJECTS = 'PROJECTS',
  PEOPLE = 'PEOPLE',
}

export function useAnalytics() {
  const [analytics, setAnalytics] = useState<AnalyticsBrowser | undefined>(
    undefined,
  );

  const parser = useRef<UAParser.UAParserInstance>();
  parser.current = new UAParser();

  const auth = getAuth();

  const { user, workspace } = useContext(UserContext);

  useEffect(() => {
    const loadAnalytics = async () => {
      if (!key) return console.error('No write key provided');
      const response = AnalyticsBrowser.load({
        writeKey: key as string,
      });
      if (!response) return console.error('Failed to load analytics');
      setAnalytics(response);
    };
    loadAnalytics();
  }, []);

  const trackUserId = useCallback(
    async ({
      email,
      name,
      avatar,
      workspaceRole,
      workspacePermission,
      weeklyEmail,
      onSuccess,
    }: {
      email?: string;
      name?: string;
      avatar?: string;
      weeklyEmail?: boolean;
      workspaceRole?: USER_ROLE;
      workspacePermission?: WORKSPACE_MEMBER_PERMISSION;
      onSuccess?: () => void;
    }) => {
      const id = auth.currentUser?.uid;

      if (!analytics || !id) return;
      const opt: {
        name?: string;
        avatar?: string;
        browser?: string;
        os?: string;
      } = {
        browser: parser?.current?.getBrowser()?.name,
        os: parser?.current?.getOS()?.name,
      };
      if (name) opt.name = name;
      if (avatar) opt.avatar = avatar;
      await analytics.identify(auth.currentUser?.uid, {
        email: email ?? auth.currentUser?.email,
        weeklyEmail: weeklyEmail ?? false,
        workspaceRole,
        ...opt,
        workspacePermission,
        timezone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone,
      });
      onSuccess?.();
    },
    [analytics, auth.currentUser?.email, auth.currentUser?.uid],
  );

  const trackGroupId = useCallback(
    async ({
      id,
      groupName,
      onSuccess,
      timeMode,
    }: {
      id: string;
      groupName: string;
      timeMode?: string;
      onSuccess?: () => void;
    }) => {
      if (!analytics) return;
      if (!id || !groupName) return;
      await analytics.group(id, {
        name: groupName,
        TIME_MODE: timeMode,
      });
      onSuccess && onSuccess();
    },
    [analytics],
  );

  const trackEvent = useCallback(
    async (
      event: string,
      groupId: string,
      properties?: Record<string, unknown>,
    ): Promise<void> => {
      if (!analytics) return;
      await analytics.track(event, {
        userId: user?.uid,
        event,
        context: {
          groupId: groupId ?? get(workspace, 'id', ''),
        },
        ...(properties ?? {}),
      });
    },
    [analytics, user?.uid, workspace],
  );

  const trackPage = useCallback(
    async (
      name: string,
      groupId?: string,
      category?: string,
      properties?: Record<string, unknown>,
    ): Promise<void> => {
      if (!analytics) return;
      await analytics.page(category, name, undefined, {
        userId: user?.uid,
        title: name,
        context: {
          groupId: groupId ?? get(workspace, 'id', ''),
        },
        ...(properties ?? {}),
      });
    },
    [analytics, user?.uid, workspace],
  );
  return {
    analytics,
    trackUserId,
    trackGroupId,
    trackPage,
    trackEvent,
  };
}
