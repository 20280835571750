import { useMutation } from '@tanstack/react-query';

import { initSubscription } from '@/services/api/workspace/workspace';

export default function useInitSubscription() {
  return useMutation(
    async (data: {
      workspaceId?: string;
      billedYearly: boolean;
      transactionId?: string;
    }) => {
      const { billedYearly, workspaceId, transactionId } = data;
      if (workspaceId) {
        const data = await initSubscription(
          workspaceId,
          billedYearly ? 'year' : 'month',
          transactionId,
        );
        return data;
      }
    },
  );
}
