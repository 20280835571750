import { useContext, useMemo, useState } from 'react';

import { format } from 'date-fns';
import { t } from 'i18next';

import { UserContext, useSubscription } from '@/contexts/UserContext';
import useCancelSubscription from '@/hooks/paddle/useCancelSubscription';
import useGetPricePreview from '@/hooks/paddle/useGetPricePreview';
import useGetProductById from '@/hooks/paddle/useGetProductById';
import { SubscriptionStatusEnum, USER_ROLE } from '@/types/enums';

import Button from '@/components/Button';
import Modal from '@/components/Modals/Modal';

import styles from './styles.module.css';
import PlanCard from '../PlanCard';

export default function FreePlanCard() {
  const { workspace, disablePayment } = useContext(UserContext);
  const { subscription, isActive } = useSubscription();
  const { mutate: cancelSubscription } = useCancelSubscription();
  const isCurrentPlan = useMemo(() => !subscription?.id, [subscription]);

  const [isDowngradeModalOpened, setIsDowngradeModalOpened] = useState(false);

  const { data: freeProduct } = useGetProductById({
    enabled: !!workspace,
    productId: import.meta.env.VITE_PADDLE_FREE_PRODUCT_ID,
  });

  const { data: freePreview } = useGetPricePreview({
    priceId: freeProduct?.prices?.[0]?.id ?? '',

    enabled: !!freeProduct?.prices?.length,
  });

  return (
    <PlanCard
      isCurrentPlan={isCurrentPlan}
      isLoading={!(freeProduct && (freePreview?.length ?? 0) > 0)}
      item={freePreview?.[0]}
      info={
        <div className={styles.infoBox}>
          <span>{freeProduct?.description}</span>
        </div>
      }
      features={freeProduct?.features ?? []}
    >
      {workspace?.role === USER_ROLE.OWNER &&
        !isCurrentPlan &&
        (!subscription || (!subscription?.scheduledAction && isActive)) && (
          <>
            <Button
              label={t('common:billing.downgradeModal.downgrade')}
              size="large"
              variant="outline"
              disabled={subscription?.waitingForUpdate}
              isLoading={subscription?.waitingForUpdate}
              style={{ position: 'relative' }}
              className={styles.buttonLabel}
              onClick={() => setIsDowngradeModalOpened(true)}
            />
            {isDowngradeModalOpened && (
              <Modal
                title={t('common:billing.downgradeModal.title')}
                modalKey={'downgrade-modal'}
                enableClose
                isOpen={isDowngradeModalOpened}
                onClose={() => setIsDowngradeModalOpened(false)}
                footer={
                  !disablePayment && (
                    <>
                      <Button
                        label={
                          subscription?.status === SubscriptionStatusEnum.active
                            ? t('common:billing.downgradeModal.downgrade')
                            : subscription?.status ===
                                SubscriptionStatusEnum.past_due
                              ? t('common:billing.downgradeModal.cancel')
                              : t('common:billing.downgradeModal.cancelTrial')
                        }
                        size="medium"
                        onClick={() => {
                          if (subscription?.id)
                            cancelSubscription(subscription.id);
                          setIsDowngradeModalOpened(false);
                        }}
                      />
                      <Button
                        label={t('common:cancel')}
                        size="medium"
                        variant="ghost"
                        onClick={() => {
                          setIsDowngradeModalOpened(false);
                        }}
                      />
                    </>
                  )
                }
              >
                <p>
                  {subscription?.status === SubscriptionStatusEnum.active
                    ? t('common:billing.downgradeModal.confirmDowngrade', {
                        date:
                          subscription?.nextBillingDate &&
                          format(subscription.nextBillingDate, 'MMMM do yyyy'),
                      })
                    : subscription?.status === SubscriptionStatusEnum.past_due
                      ? t(
                          'common:billing.downgradeModal.confirmCancelSubscription',
                        )
                      : t('common:billing.downgradeModal.confirmCancelTrial')}
                </p>
              </Modal>
            )}
          </>
        )}
    </PlanCard>
  );
}
