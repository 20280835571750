import styles from './styles.module.css';

type Props = {
  label: JSX.Element | string;
  description: JSX.Element | string;
  action?: JSX.Element;
};

export default function ActionRow({ label, description, action }: Props) {
  return (
    <div className={styles.actionRow}>
      <div className={styles.actionRow_text}>
        <span className={styles.actionRow_label}>{label}</span>
        <span className={styles.actionRow_description}>{description}</span>
      </div>
      {action}
    </div>
  );
}
