import { useMemo } from 'react';

import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
type Props = {
  htmltext: string;
};
export default function SafeHtml({ htmltext }: Props) {
  return useMemo(
    () => parse(DOMPurify.sanitize(htmltext), { trim: true }),
    [htmltext],
  );
  //   return _html;
}
